<template>
  <QBtn
    html-type="submit"
    size="large"
    type="success">
    <slot>Сохранить</slot>
  </QBtn>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
