<template>
  <QBtn
    size="large"
    type="warning">
    <slot>Сбросить</slot>
  </QBtn>
</template>

<script>
export default {
};
</script>
