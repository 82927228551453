<template>
  <RouterLink class="ant-btn ant-btn-primary ant-btn-lg">
    <QIcon type="plus" />
    Добавить
  </RouterLink>
</template>

<script>
export default {
};
</script>
