import axios from 'axios';
import store from '@/store/index';

// process.env.NODE_ENV === 'production'
//   ? 'https://optiback.qltlab.kz/api/v1'
//   : 'https://optibacktest.qltlab.kz/api/v1'

const instanceConfig = {
  // timeout: 5000,
  baseURL: process.env.VUE_APP_SERVER_URL,
  headers: { 'Content-Type': 'application/json' },
};

function request(config) {
  // FIXME
  // eslint-disable-next-line
  config.headers.Authorization = `Bearer ${store.state.auth.token}`;
  return config;
}

function requestError(error) {
  // Do something with request error
  return Promise.reject(error);
}

function response(response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  switch (response.status) {
    case 200:
      // yay!
      break;
    // any other cases
    default:
    // default case
  }

  return response;
}

function responseError(error) {
  /**
   * This is a central point to handle all
   * error messages generated by HTTP requests
   */

  return Promise.reject(error);
}

const instance = axios.create(instanceConfig);

instance.interceptors.request.use(request, requestError);
instance.interceptors.response.use(response, responseError);

export function useInstance() {
  return {
    get: (url, config) => instance.get(url, config),
    delete: (url, config) => instance.delete(url, config),
    post: (url, config, data) => instance.post(url, data, config),
    put: (url, config, data) => instance.put(url, data, config),
    patch: (url, config, data) => instance.patch(url, data, config),
  };
}
